body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.App {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  background-color: #232a42;
}

.quote_container {
  background-image: url("kanye-west-0814-GQ-FEKW01.01.jpg") !important;
  mix-blend-mode: normal !important;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  background-image: url("yeezus-1593466362055-2261.jpg");
  mix-blend-mode: hard-light;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40% 10%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.quote {
  /* background-color: #fff; */
  padding: 2rem;
  margin: 4rem;
  /* box-shadow: 3px 3px 30px 1px rgba(0, 0, 0, 0.75); */
  border-radius: 3px;
  width: 80%;
  color: #fff;
}

.quote a:link,
.quote a:visited,
.quote a:active {
  text-decoration: none;
  color: inherit;
}

.quote a:hover {
  text-decoration: underline;
}

blockquote {
  margin: 0.5rem;
  color: #fff;
}

blockquote p {
  animation-name: quote;
  animation-duration: 2s;
}

@keyframes quote {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.button {
  background-color: #3c5578;
  border: none;
  -webkit-appearance: none;
  -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: white;
  padding: 1rem;
  text-align: center;
  margin: 0.25rem;
  cursor: pointer;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.button:hover {
  background-color: #546378;
  -webkit-appearance: none;
  -webkit-box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.5);
  transform: translateY(-5px);
  border: none;
}

@media (min-width: 576px) {
  .quote {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .quote {
    width: 40%;
  }
}
